
.iti__flag-container {
    position: absolute !important;
    top: 0 !important;
    bottom: 8px !important;
    right: 0 !important;
    padding: 1px !important;
}

.iti input, .iti input[type=text], .iti input[type=tel] {
    position: relative;
    z-index: 0;
    margin-top: 0 !important;
    margin-bottom: 8px !important;
    padding-right: 36px;
    margin-right: 0;
    width: 256px !important;
}

.iti__selected-flag {
    z-index: 1;
    position: relative;
    display: flex
;
    align-items: center;
    height: 100%;
    padding: 0 6px 0 8px;
    width: 50px !important;
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2a3141;
    display: flex;
    justify-content: center;
    align-items: center;
	z-index: 600;
	gap: 10px;
	transition: transform 2s ease-in-out;
	overflow: hidden
}
.dot {
    height: 20px;
    width: 20px;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
    animation: bounce 1.4s infinite ease-in-out both;
}

@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
        opacity: 0.5;
    }
    40% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes scaleDecrease {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(0);
    }
}
#tick {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 12px; /* Adjust size as needed */
    height: 6px; /* Adjust size as needed */
    border: solid white;
    border-width: 0 3px 3px 0;
	transform: scale(4, 4) translate(10%, -400%) rotate(315deg) scaleX(-1);
    display: none;
	z-index: 300;
	animation: fadeIn 2s forwards;
}
.dot:nth-child(1) {
    animation-delay: -0.32s;
}
.dot:nth-child(2) {
    animation-delay: -0.16s;
}
.dot:nth-child(3) {
    animation-delay: 0s;
}

.slide-down {
    transform: translateY(100%); /* Slide down */
}
/* General Styles */
/* Landscape Message Styles */
#landscape-message {
    display: none; /* Hidden by default */
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    background: black;
    color: white;
    font-size: 1.5rem;
    z-index: 9999;
    flex-direction: column;
}

/* Center the message content */
.message-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

/* Rotate Icon */
.rotate-icon {
    width: 90px;
    height: 160px;
    border: 5px solid white;
    border-radius: 15px;
    position: relative;
    animation: rotatePhone 3s infinite ease-in-out;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1));
}

/* Add a smaller screen inside the icon */
.rotate-icon::before {
    content: "";
    position: absolute;
    width: 80%;
    height: 80%;
    background: white;
    top: 10%;
    left: 10%;
    border-radius: 10px;
}

/* Keyframe Animation: Rotate Phone */
@keyframes rotatePhone {
    0%, 100% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(90deg);
    }
}

/* Keyframe Animation: Tap Effect */
@keyframes tapEffect {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

/* Styling for the text message */
#landscape-message p {
    margin: 0;
    font-size: 18px;
    color: #fff;
    text-align: center;
    max-width: 80%;
    line-height: 1.5;
}

html, body {
    padding: 0;
    margin: 0;
    overflow: hidden;
    overflow-x: hidden;
    -webkit-text-size-adjust: none;
    width: 100%;
    height: 100%;
    position: fixed;
}

/* Display game normally in landscape mode */
@media (orientation: landscape) {
    #landscape-message {
        display: none;
    }

    #canvas, #overlays {
        display: block;
    }
}

/* Show rotation message in portrait mode */
@media (orientation: portrait) {
    #landscape-message {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 0;
        left: 0;
        background: black;
        color: white;
        font-size: 1.5rem;
        z-index: 9999;
    }

    #canvas, #overlays {
        display: none;
    }
}

input[type=checkbox] {
    cursor: pointer;
}

/* Overlay Styles */
.overlay {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Centering Container */
#centerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    position: relative;
    top: -60px;
}

/* Dialog Styles */
#helloDialog {
    text-align: center;
    z-index: 1;
    opacity: 1;
    transform-origin: center;
    background-color: #fff;
    border-radius: 15px;
    flex-direction: column;
    place-items: center;
    width: 400px;
    max-width: 100%;
    height: 560px;
    max-height: 100%;
    padding: 5px 15px;
    display: flex;
    position: relative;
    border-style: solid;
    border-color: lightgray;
}

/* Guest Profile Panel */
#guestProfilePanel {
    text-align: center;
    transform-origin: center;
    position: absolute;
    background-color: #f5f5f5;
    border-style: solid;
    border-color: #d3d3d3;
    border-radius: 15px;
    width: 300px;
    padding: 20px;
    transition: opacity .5s ease-in-out;
    top: -3px;
    left: -309px;
    box-shadow: 0 0 10px #0000001a;
    border-style: solid;
    border-color: lightgray;
}

#worldSelection {
    text-align: center;
    transform-origin: center;
    background-color: #f5f5f5;
    border-style: solid;
    border-color: #d3d3d3;
    border-radius: 15px;
    width: 232px;
    padding: 10px;
    transition: opacity .5s ease-in-out;
    position: absolute;
    top: -3px;
    right: -241px;
    box-shadow: 0 0 10px #0000001a;
    border-style: solid;
    border-color: lightgray;
}

#server-btns {
    position: relative;
}

#ffa-btn, #teams-btn, #duels-btn, #experimental-btn {
    cursor: pointer;
    color: #fff;
    background-color: #70859a;
    background-position: 0 14px;
    background-size: 100%;
    border-style: solid;
    border-color: #fff;
    border-radius: 14px;
    width: 100px;
    height: 142px;
    padding-top: 80px;
    font-size: smaller;
    transition-duration: .4s;
}

#ffa-btn {
	background-image: url(../img/ffa-bck.png);
}
#teams-btn {
	background-image: url(../img/teams-bck.png);
}
#duels-btn {
	background-image: url(../img/duels-bck.png);
}
#experimental-btn {
	background-image: url(../img/mega-bck.png);
}
#ffa-btn:hover {
    background-color: rgb(56 74 119);
    color: white;
}
#duels-btn:hover {
    background-color: rgb(160 60 60);
    color: white;
}
#teams-btn:hover {
    background-color: rgb(146 100 148);
    color: white;
}
#experimental-btn:hover {
    background-color: rgb(98 154 80);
    color: white;
}

.active {
    background-color: rgb(56, 74, 119); 
  }

#profilePanelTop {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#guestProfilePic {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 15px;
}

#guestUsername {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

#guestProfileText {
    font-size: 14px;
    color: #555;
    margin-bottom: 20px;
    text-align: center;
    padding: 0 10px;
}

#loginBtn, #loginSubmitBtn, #registerSubmitBtn, #showRegisterBtn, #showLoginBtn {
    width: 80%;
    padding: 10px;
    font-size: 16px;
    margin-top: 10px;
}

.logout-btn {
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    flex-wrap: wrap;
    place-content: center;
    width: 40px;
    height: 40px;
    padding: 0;
    font-size: 16px;
    line-height: 40px;
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    border-color: ghostwhite;
}

.btn-danger {
    color: #fff;
    background-color: #c7c7c7;
    border-color: #979797;
}
.btn-danger:active {
    color: #fff;
    background-color: #c7c7c7;
    border-color: #979797;
}

.logout-btn .glyphicon {
    font-size: 20px;
    line-height: 40px;
}

.logout-btn:hover {
    background-color: #e9e9e9;
}

/* Hide login and register forms initially */
#loginForm, #registerForm {
    display: none;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
}

.login-error-message {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-top: 10px;
}

input {
    border: 1px solid #ccc; /* Default border */
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: border-color 0.5s ease; /* Smooth transition for the border color */
}

input:focus {
    outline: none;
    border-color: #007bff; /* Blue border on focus */
}


input.invalid {
    border-color: red; /* Red border for invalid fields */
}



@media (max-width: 1366px), (max-width: 1280px), (max-width: 1440px) {
    #helloDialog, #chat-container {
        transform: scale(0.8);
    }
}

/* Tablet devices */
@media (max-width: 1024px), (max-width: 768px) {
    #helloDialog {
        transform: scale(0.7);
        top: 10vh;
    }
}

/* Small mobile devices */
@media (max-width: 640px) {
    #helloDialog {
        transform: scale(0.5);
        top: 0vh;
    }
}
/* Small mobile devices */
@media (max-width: 480px) {
    #helloDialog {
        transform: scale(0.5);
    }
}

#stats-container, #score-container {
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    font-family: Ubuntu;
    position: absolute;
    top: 60px;
    left: 0;
}

@media (max-width: 1024px) {
    #stats-container, #score-container {
        color: #fff;
        border-radius: 5px;
        padding: 10px;
        font-family: Ubuntu;
        position: absolute;
        top: 8vh;
        left: 0;
        transform: scale(0.6);
    }
}

#pieChartCanvas {
    width: 200px;
    height: 240px;
    margin-bottom: -40px;
}

.leaderboard {
    display: flex;
    position: fixed;
    width: 260px;
    justify-content: center;
    flex-direction: column;
    background: rgba(0,0,0,.3);
    overflow: hidden;
    padding: 10px;
    right: 5px;
    top: 5px;
    transform-origin: top right;
    transform: scale(1);
    font-size: 30px;
    color: #ffffff;
    font-weight: 500;
    text-align: center;
    user-select: none;
	pointer-events: none;
}
.leaderboard .positions {
    text-align: left;
    font-size: 18px;
}

@media (max-width: 1024px) {
    .leaderboard {
        display: flex;
        position: fixed;
        width: 260px;
        justify-content: center;
        flex-direction: column;
        background: rgba(0,0,0,.3);
        overflow: hidden;
        padding: 10px;
        right: 5px;
        top: 5px;
        transform-origin: top right;
        z-index: 1;
        transform: scale(0.6);
        font-size: 30px;
        color: #ffffff;
        font-weight: 500;
        text-align: center;
        user-select: none;
        pointer-events: none;
    }
}
/* Additional Styles */
#connecting {
    width: 350px;
    background-color: #fff;
    border-radius: 15px;
    padding: 5px 15px;
    text-align: center;
    top: 10%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -140%);
}

.loader {
    box-sizing: border-box;
    border: 5px solid #686868;
    border-bottom-color: #0000;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: 1s linear infinite rotation;
    display: inline-block;
}

#nick {
    width: 70%;
    text-align: center;
    border-radius: 4px;
    min-height: 34px;
}

#skin {
    width: 33%;
    float: right;
}

#gamemode {
    margin-top: 5px;
    width: 100%;
}

.checkbox {
    height: 170px;
    display: flex;
    flex-flow: column wrap;
    align-content: space-evenly;
    gap: 5px 6px;
    padding-top: 3px;
    text-align: justify;
    position: relative; /* Allow positioning of child elements */
}

#logo {
    display: block;
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    transition: all 2s ease;
}

#logo:hover {
    filter: hue-rotate(139deg);
}

#chat_textbox {
    transition: all .5s ease-in-out;
    position: absolute;
    z-index: 1;
    bottom: 10px;
    background: rgba(0, 0, 0, .2);
    border: 0;
    outline: none;
    color: #fff;
    height: 30px;
    text-indent: 12px;
    left: 10px;
    width: inherit;
    font-family: "Ubuntu";
}

#chat_textbox:focus {
    background: rgba(0, 0, 0, .5);
}

#chat-canvas {
    position: absolute;
    bottom: 42px;
    left: 10px;
    color: white;
    padding: 10px;
    border-radius: 5px;
    width: inherit;
}

#chat-container {
    position: absolute;
    bottom: 10px;
    left: 10px;
    width: 600px;
    height: 300px;
    transform-origin: bottom left;
}

/* Responsive Chat Container */
@media (max-width: 1024px) {
    #chat-container {
        transform: scale(0.6);
    }
}

@media (max-width: 768px) {
    #chat-container {
        transform: scale(0.4);
    }
}

@media (max-width: 480px) {
    #chat-container {
        transform: scale(0.2);
    }
}

#footer {
    text-align: center;
    margin-bottom: 10px;
    margin-top: -10px;
}

#button-container {
    width: 400px;
    margin: 10px;
    max-width: 100%;
}

#play-btn {
    width: 73%;
}

#spectate-btn {
    width: 12%;
    float: right;
    top: 0;
}

#settings-btn {
    width: 12%;
    float: left;
}

#gallery-btn {
    width: 100%;
    display: none; /* Visible when skins are loaded */
}

#instructions {
    margin-top: 20px;
    animation: fadeIn 2s;
    animation-fill-mode: forwards;
}

#touchCircle, #splitBtn, #ejectBtn {
    position: fixed;
    z-index: 2;
}

#touchCircle {
    width: 4vw;
    height: 4vw;
    border-radius: 2vw;
    background: #f00;
    opacity: 0.3;
    z-index: 3;
}

#splitBtn, #ejectBtn {
    width: 8vw;
    height: 8vw;
    border-radius: 4vw;
    background: #777;
    opacity: 0.5;
}

#splitBtn {
    bottom: 4vh;
    right: 18vw;
}

#ejectBtn {
    bottom: 16vw;
    right: 10px;
}

#playSounds:checked + #soundsVolume {
    display: inline;
}

#soundsVolume {
    display: none;
    width: 100px;
    height: 18px;
    padding: 0; /* IE fix */
    vertical-align: middle;
}

#settings {
    opacity: 0;
    animation: fadeIn 2s;
    animation-fill-mode: forwards;
    margin-top: 20px;
    height: 150px;
}

.quality-selector-wrapper {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

#select {
    text-align: center;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    } 
    to {
        opacity: 1;
    }
}

@keyframes resizeAndRotate {
    0% {
        transform: scale(1) rotate(0deg);
    }
    50% {
        transform: scale(1.5) rotate(12deg);
    }
    100% {
        transform: scale(1) rotate(0deg);
    }
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
