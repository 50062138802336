.iti__flag-container {
  padding: 1px !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 8px !important;
  right: 0 !important;
}

.iti input, .iti input[type="text"], .iti input[type="tel"] {
  z-index: 0;
  margin-right: 0;
  padding-right: 36px;
  position: relative;
  width: 256px !important;
  margin-top: 0 !important;
  margin-bottom: 8px !important;
}

.iti__selected-flag {
  z-index: 1;
  align-items: center;
  height: 100%;
  padding: 0 6px 0 8px;
  display: flex;
  position: relative;
  width: 50px !important;
}

#preloader {
  z-index: 600;
  background-color: #2a3141;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  transition: transform 2s ease-in-out;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.dot {
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: 1.4s ease-in-out infinite both bounce;
  display: inline-block;
}

@keyframes bounce {
  0%, 80%, 100% {
    opacity: .5;
    transform: scale(0);
  }

  40% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleDecrease {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0);
  }
}

#tick {
  content: "";
  z-index: 300;
  border: 3px solid #fff;
  border-width: 0 3px 3px 0;
  width: 12px;
  height: 6px;
  animation: 2s forwards fadeIn;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: scale(4)translate(10%, -400%)rotate(315deg)scaleX(-1);
}

.dot:first-child {
  animation-delay: -.32s;
}

.dot:nth-child(2) {
  animation-delay: -.16s;
}

.dot:nth-child(3) {
  animation-delay: 0s;
}

.slide-down {
  transform: translateY(100%);
}

#landscape-message {
  text-align: center;
  color: #fff;
  z-index: 9999;
  background: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-size: 1.5rem;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.message-content {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  display: flex;
}

.rotate-icon {
  background: linear-gradient(45deg, #fff3, #ffffff1a);
  border: 5px solid #fff;
  border-radius: 15px;
  width: 90px;
  height: 160px;
  animation: 3s ease-in-out infinite rotatePhone;
  position: relative;
}

.rotate-icon:before {
  content: "";
  background: #fff;
  border-radius: 10px;
  width: 80%;
  height: 80%;
  position: absolute;
  top: 10%;
  left: 10%;
}

@keyframes rotatePhone {
  0%, 100% {
    transform: rotate(0);
  }

  50% {
    transform: rotate(90deg);
  }
}

@keyframes tapEffect {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: .5;
    transform: scale(1.2);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

#landscape-message p {
  color: #fff;
  text-align: center;
  max-width: 80%;
  margin: 0;
  font-size: 18px;
  line-height: 1.5;
}

html, body {
  -webkit-text-size-adjust: none;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  overflow: hidden;
}

@media (orientation: landscape) {
  #landscape-message {
    display: none;
  }

  #canvas, #overlays {
    display: block;
  }
}

@media (orientation: portrait) {
  #landscape-message {
    text-align: center;
    color: #fff;
    z-index: 9999;
    background: #000;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    font-size: 1.5rem;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
  }

  #canvas, #overlays {
    display: none;
  }
}

input[type="checkbox"] {
  cursor: pointer;
}

.overlay {
  opacity: 0;
  background-color: #00000080;
  position: absolute;
  inset: 0;
}

#centerContainer {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  position: relative;
  top: -60px;
}

#helloDialog {
  text-align: center;
  z-index: 1;
  opacity: 1;
  transform-origin: center;
  background-color: #fff;
  border-style: solid;
  border-color: #d3d3d3;
  border-radius: 15px;
  flex-direction: column;
  place-items: center;
  width: 400px;
  max-width: 100%;
  height: 560px;
  max-height: 100%;
  padding: 5px 15px;
  display: flex;
  position: relative;
}

#guestProfilePanel {
  text-align: center;
  transform-origin: center;
  background-color: #f5f5f5;
  border-style: solid;
  border-color: #d3d3d3;
  border-radius: 15px;
  width: 300px;
  padding: 20px;
  transition: opacity .5s ease-in-out;
  position: absolute;
  top: -3px;
  left: -309px;
  box-shadow: 0 0 10px #0000001a;
}

#worldSelection {
  text-align: center;
  transform-origin: center;
  background-color: #f5f5f5;
  border-style: solid;
  border-color: #d3d3d3;
  border-radius: 15px;
  width: 232px;
  padding: 10px;
  transition: opacity .5s ease-in-out;
  position: absolute;
  top: -3px;
  right: -241px;
  box-shadow: 0 0 10px #0000001a;
}

#server-btns {
  position: relative;
}

#ffa-btn, #teams-btn, #duels-btn, #experimental-btn {
  cursor: pointer;
  color: #fff;
  background-color: #70859a;
  background-position: 0 14px;
  background-size: 100%;
  border-style: solid;
  border-color: #fff;
  border-radius: 14px;
  width: 100px;
  height: 142px;
  padding-top: 80px;
  font-size: smaller;
  transition-duration: .4s;
}

#ffa-btn {
  background-image: url("ffa-bck.434ec686.png");
}

#teams-btn {
  background-image: url("teams-bck.c714dd8a.png");
}

#duels-btn {
  background-image: url("duels-bck.4b6aa4e5.png");
}

#experimental-btn {
  background-image: url("mega-bck.44985634.png");
}

#ffa-btn:hover {
  color: #fff;
  background-color: #384a77;
}

#duels-btn:hover {
  color: #fff;
  background-color: #a03c3c;
}

#teams-btn:hover {
  color: #fff;
  background-color: #926494;
}

#experimental-btn:hover {
  color: #fff;
  background-color: #629a50;
}

.active {
  background-color: #384a77;
}

#profilePanelTop {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#guestProfilePic {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

#guestUsername {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}

#guestProfileText {
  color: #555;
  text-align: center;
  margin-bottom: 20px;
  padding: 0 10px;
  font-size: 14px;
}

#loginBtn, #loginSubmitBtn, #registerSubmitBtn, #showRegisterBtn, #showLoginBtn {
  width: 80%;
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
}

.logout-btn {
  text-align: center;
  cursor: pointer;
  border-color: #f8f8ff;
  border-radius: 50%;
  flex-wrap: wrap;
  place-content: center;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 16px;
  line-height: 40px;
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.btn-danger, .btn-danger:active {
  color: #fff;
  background-color: #c7c7c7;
  border-color: #979797;
}

.logout-btn .glyphicon {
  font-size: 20px;
  line-height: 40px;
}

.logout-btn:hover {
  background-color: #e9e9e9;
}

#loginForm, #registerForm {
  opacity: 0;
  transition: opacity .5s ease-in-out;
  display: none;
}

.login-error-message {
  color: red;
  text-align: center;
  margin-top: 10px;
  font-size: 14px;
}

input {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  transition: border-color .5s;
}

input:focus {
  border-color: #007bff;
  outline: none;
}

input.invalid {
  border-color: red;
}

@media (width <= 1366px), (width <= 1280px), (width <= 1440px) {
  #helloDialog, #chat-container {
    transform: scale(.8);
  }
}

@media (width <= 1024px), (width <= 768px) {
  #helloDialog {
    top: 10vh;
    transform: scale(.7);
  }
}

@media (width <= 640px) {
  #helloDialog {
    top: 0;
    transform: scale(.5);
  }
}

@media (width <= 480px) {
  #helloDialog {
    transform: scale(.5);
  }
}

#stats-container, #score-container {
  color: #fff;
  border-radius: 5px;
  padding: 10px;
  font-family: Ubuntu;
  position: absolute;
  top: 60px;
  left: 0;
}

@media (width <= 1024px) {
  #stats-container, #score-container {
    color: #fff;
    border-radius: 5px;
    padding: 10px;
    font-family: Ubuntu;
    position: absolute;
    top: 8vh;
    left: 0;
    transform: scale(.6);
  }
}

#pieChartCanvas {
  width: 200px;
  height: 240px;
  margin-bottom: -40px;
}

.leaderboard {
  transform-origin: 100% 0;
  color: #fff;
  text-align: center;
  user-select: none;
  pointer-events: none;
  background: #0000004d;
  flex-direction: column;
  justify-content: center;
  width: 260px;
  padding: 10px;
  font-size: 30px;
  font-weight: 500;
  display: flex;
  position: fixed;
  top: 5px;
  right: 5px;
  overflow: hidden;
  transform: scale(1);
}

.leaderboard .positions {
  text-align: left;
  font-size: 18px;
}

@media (width <= 1024px) {
  .leaderboard {
    transform-origin: 100% 0;
    z-index: 1;
    color: #fff;
    text-align: center;
    user-select: none;
    pointer-events: none;
    background: #0000004d;
    flex-direction: column;
    justify-content: center;
    width: 260px;
    padding: 10px;
    font-size: 30px;
    font-weight: 500;
    display: flex;
    position: fixed;
    top: 5px;
    right: 5px;
    overflow: hidden;
    transform: scale(.6);
  }
}

#connecting {
  text-align: center;
  background-color: #fff;
  border-radius: 15px;
  width: 350px;
  padding: 5px 15px;
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -140%);
}

.loader {
  box-sizing: border-box;
  border: 5px solid #686868;
  border-bottom-color: #0000;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: 1s linear infinite rotation;
  display: inline-block;
}

#nick {
  text-align: center;
  border-radius: 4px;
  width: 70%;
  min-height: 34px;
}

#skin {
  float: right;
  width: 33%;
}

#gamemode {
  width: 100%;
  margin-top: 5px;
}

.checkbox {
  text-align: justify;
  flex-flow: column wrap;
  align-content: space-evenly;
  gap: 5px 6px;
  height: 170px;
  padding-top: 3px;
  display: flex;
  position: relative;
}

#logo {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  transition: all 2s;
  display: block;
}

#logo:hover {
  filter: hue-rotate(139deg);
}

#chat_textbox {
  z-index: 1;
  color: #fff;
  text-indent: 12px;
  width: inherit;
  background: #0003;
  border: 0;
  outline: none;
  height: 30px;
  font-family: Ubuntu;
  transition: all .5s ease-in-out;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

#chat_textbox:focus {
  background: #00000080;
}

#chat-canvas {
  color: #fff;
  width: inherit;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  bottom: 42px;
  left: 10px;
}

#chat-container {
  transform-origin: 0 100%;
  width: 600px;
  height: 300px;
  position: absolute;
  bottom: 10px;
  left: 10px;
}

@media (width <= 1024px) {
  #chat-container {
    transform: scale(.6);
  }
}

@media (width <= 768px) {
  #chat-container {
    transform: scale(.4);
  }
}

@media (width <= 480px) {
  #chat-container {
    transform: scale(.2);
  }
}

#footer {
  text-align: center;
  margin-top: -10px;
  margin-bottom: 10px;
}

#button-container {
  width: 400px;
  max-width: 100%;
  margin: 10px;
}

#play-btn {
  width: 73%;
}

#spectate-btn {
  float: right;
  width: 12%;
  top: 0;
}

#settings-btn {
  float: left;
  width: 12%;
}

#gallery-btn {
  width: 100%;
  display: none;
}

#instructions {
  margin-top: 20px;
  animation: 2s forwards fadeIn;
}

#touchCircle, #splitBtn, #ejectBtn {
  z-index: 2;
  position: fixed;
}

#touchCircle {
  opacity: .3;
  z-index: 3;
  background: red;
  border-radius: 2vw;
  width: 4vw;
  height: 4vw;
}

#splitBtn, #ejectBtn {
  opacity: .5;
  background: #777;
  border-radius: 4vw;
  width: 8vw;
  height: 8vw;
}

#splitBtn {
  bottom: 4vh;
  right: 18vw;
}

#ejectBtn {
  bottom: 16vw;
  right: 10px;
}

#playSounds:checked + #soundsVolume {
  display: inline;
}

#soundsVolume {
  vertical-align: middle;
  width: 100px;
  height: 18px;
  padding: 0;
  display: none;
}

#settings {
  opacity: 0;
  height: 150px;
  margin-top: 20px;
  animation: 2s forwards fadeIn;
}

.quality-selector-wrapper {
  text-align: center;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
}

#select {
  text-align: center;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes resizeAndRotate {
  0% {
    transform: scale(1)rotate(0);
  }

  50% {
    transform: scale(1.5)rotate(12deg);
  }

  100% {
    transform: scale(1)rotate(0);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
/*# sourceMappingURL=index.929bc017.css.map */
